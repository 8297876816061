<template>
  <div class="verification-container ">
    <h1 class="title">Masukkan Kode Verifikasi</h1>
    <p class="description">
      Silahkan Masukkan Kode Verifikasi Dokter yang dapat diperoleh dari dokter atau dari PCC YKI.
    </p>
    <div class="code-inputs">
      <input
        v-for="(_, index) in verificationCode"
        :key="index"
        class="code-box"
        v-model="verificationCode[index]"
        maxlength="1"
        @input="moveFocus($event, index)"
        @keydown.backspace="moveBack($event, index)"
      />
    </div>
    <button class="verify-button" @click="verifyCode">LANJUTKAN PROSES</button>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  watch,
  computed,
} from 'vue'
import { notification } from 'ant-design-vue'
import { getPatientProgram, confirmProgram, updateTestLab } from '../../../services/axios/pulih'
export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const verificationCode = ref(['', '', '', '', '', ''])
    const store = useStore()
    const dataProgramPatient = ref()
    onMounted(async () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const res = await getPatientProgram(userData.user.code)
      dataProgramPatient.value = res
    })
    const verifyCode = async () => {
      const code = verificationCode.value.join('')
      console.log('Verifying code:', code)
      const attribute = 'konfirmasiDokter'
      const value = 'konfirmasiDokter'
      store.dispatch('updateAttribute', { attribute, value })
      const res = await confirmProgram(dataProgramPatient.value.programs[0].id, code)
      if (!res) {
        openNotificationWithIcon('error')
      }
      router.push(`/mainProgram`)
      // Implement the verification logic or API call here
    }

    const moveFocus = (event, index) => {
      if (event.target.value) {
        if (index < verificationCode.value.length - 1) {
          const nextInput = event.target.nextElementSibling
          if (nextInput) {
            nextInput.focus()
          }
        }
      }
    }
    const moveBack = (event, index) => {
      if (index > 0 && !event.target.value) {
        const previousInput = event.target.previousElementSibling
        if (previousInput) {
          previousInput.focus()
        }
      }
    }
    const openNotificationWithIcon = type => {
      notification[type]({
        message: 'Notification Title',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
      })
    }

    return {
      openNotificationWithIcon,
      moveBack,
      moveFocus,
      verifyCode,
      verificationCode,
    }
  },
}
</script>

<style scoped>
.verification-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

.title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.description {
  text-align: center;
  margin-bottom: 20px;
}

.code-inputs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.code-box {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.verify-button {
  background-color: #f90;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-button:hover {
  background-color: #e80;
}
</style>
